<template>
  <div>
    <LoginPage  :showTitle="showTitle" @swicthPage="swicthPage"></LoginPage>
  </div>
</template>

<script>
import LoginPage from './login.vue'
export default {
  components: {LoginPage},
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showPage: 'login'
    }
  },
  methods: {
    swicthPage (page) {
      this.showPage = page
      this.$emit('swicthPage', page)
    }
  }
}
</script>
<style lang="scss" scoped>
.leftbody {
  background-image: url('../../../assets//img//bg/7.png');
  background-size: 100% 100%;
  height: 100%;
  right: 50%;
  position: absolute;
  left: 0px;
}
@media screen and (max-width: 750px) {
  .leftbody {
    visibility: hidden;
  }
}
</style>
