<template>
  <el-form class="login-body" @submit.native.prevent>
    <div class="title" v-if="showTitle">
    <div class="cn">账号登录</div>
    </div>
    <el-input class="input" v-model="form.username" :class="{focus: focusName == 'username'}" @focus="focusName = 'username'" placeholder="请输入用户名">
        <i  slot="suffix" class="el-icon-user"></i>
    </el-input>
    <el-input class="input" v-model="form.password" :class="{focus: focusName == 'password'}"  @focus="focusName = 'password'"  type="password" placeholder="请输入密码">
    <i slot="suffix" class="el-icon-lock"></i>
    </el-input>
   
    <div class="btns">
      <el-button class="btn login" native-type="submit" @click="login" :loading="loginLoading">登录</el-button>
    </div>
    <div class="login-row">
    <!-- <div class="link"  @click="$emit('swicthPage', 'forgot')">忘记密码</div>
    <div  class="link" @click="$emit('swicthPage', 'register')">去注册</div> -->
    </div>
  </el-form>
</template>

<script>
import { LoginRequest } from '@/api'
import { md5, decryptByAES } from '@/util/crypto'
export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      focusName: '',
      loginLoading: false
    }
  },
  methods: {
    gotopage(page) {
      this.$router.replace({ path: page })
    },
    login() {
      if (!(this.form.username && this.form.password)) {
        return
      }
      this.loginLoading = true
      setTimeout(() => {
        let params = Object.assign({}, this.form)
        params.password = md5(params.password)
        LoginRequest(params).then(res => {
          console.log(res)
          this.loginLoading = false
          if (res.code === 20000) {
            let token = res.data.token
            let user = JSON.parse(decryptByAES(res.data.user))
            console.log(token, user)
            sessionStorage.setItem('token', token)
            sessionStorage.setItem('userid', user.id)
            this.$setCache('config-user', {
              token: token,
              userid: user.id,
              username: user.username,
              personName: user.nickname,
              role: user.role,
              version: user.version
            })
            let page = sessionStorage.getItem('history-page')
            if (page && ['/check', '/buy'].indexOf(page) !== -1) {
              this.gotopage(page)
            } else {
              this.gotopage('/')
            }
            this.$event.$emit('login')
          }
        })
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
$width: calc(100% - 40px);
$height: 45px;

.login-body {
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 50px;
  .login-row {
    margin: 20px 30px;
    width: $width;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-size: 14px;
    width: $width;
    text-align: left;
    margin-bottom: 20px;
    font-weight: 400;
    color: #161616;
    padding-bottom: 6.5px;
    border-bottom: 1px solid #F5F4F8;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      height: 1px;
      bottom: 0px;
      left: 0px;
      width: 60px;
      background-color: #0B4ECD;
    }
    .cn {
    }
    .zw {
      font-size: 18px;
      margin-left: 30px;
      font-weight: normal;
      color: #787878;
      text-shadow: none;
      letter-spacing: 0.1em;
    }
  }
  .link {
    cursor: pointer;
    font-size: 12px;
    &:hover {
      color: $primary-color;
    }
  }

  ::v-deep {
    .el-input__inner {
      height: $height;
      font-size: 14px;
    }

    .el-button+.el-button {
      margin: 0px;
    }
  }

  .input {
    width: $width;
    margin-top: 15px;
   
    ::v-deep {
      .el-input__suffix {
        display: flex;
        align-items: center;
        i {
          padding: 0 10px;
          font-size: 18px;
        }
      }
      .el-input__inner {
        border-radius: 5px;
        padding-left: 20px;
      }
      &:active {
        i {
          color: #0B4ECD;
        }
      }
    }
    &.focus {
      i {
          color: #0B4ECD;
        }
    }
  }

  .forget {
    width: $width;
    text-align: right;
    color: #fff;
    margin-top: 5px;

    span {
      cursor: pointer;
    }
  }

  .btns {
    width: $width;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;

    .btn {
      width: 100%;
      height: $height;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      text-shadow: 1px 1px 0px rgba(101, 83, 0, 0.55);
    }

    .login {
      background-color: $primary-color;
      border: none;
      color: #fff;
      border-radius: 22px;
      &:hover {
        background-color: rgba($color: $primary-color, $alpha: 0.9);
      }
    }

    .regist {
      background-color: transparent;
      border: 2px solid $primary-color;
      color: #fff;

      &:hover {
        background-color: rgba($color: $primary-color, $alpha: 0.9);
      }
    }
  }

}
@media screen and (max-width: 750px) {
  .login-body {
    left: 0px;
    padding: 10px;
  }
}
</style>